/* body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
}
 */
.loginPage {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: linear-gradient(
      135deg,
      rgba(0, 64, 128, 0.8),
      rgba(0, 102, 204, 0.8)
    ),
    url("../assets/pattern.png");
  background-size: cover, cover;
  background-blend-mode: overlay;
  background-color: rgb(240, 240, 240, 0.5);
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 150px);
  padding: 2rem;
  margin-bottom: 4rem;
  margin: 0 auto; /* Center horizontally */
  max-width: 1200px; /* Maximum width */
}

.loginBox {
  background: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
}

.logoSection {
  text-align: center;
  margin-bottom: 2rem;
}

.logoSection h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
}

.subtitle {
  color: #6b7280;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.loginForm {
  width: 80%;
  margin: 0 auto; /* Center the form */
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formGroup label {
  margin-top: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.input {
  width: 100%;
  padding: 0.625rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 0.875rem;
  background: rgba(255, 255, 255, 0.9);
}

.input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.loginButton {
  margin-top: 1rem;
  width: 100%;
  padding: 0.625rem 1rem;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.loginButton:hover {
  background-color: #1d4ed8;
}

.formFooter {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.signupLink,
.forgotPasswordLink {
  color: #2563eb;
  font-size: 0.875rem;
  text-decoration: none;
}

.forgotPasswordLink {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.signupLink:hover,
.forgotPasswordLink:hover {
  text-decoration: underline;
}

.errorMessage,
.responseMessage {
  padding: 0.75rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  text-align: center;
}

.errorMessage {
  background-color: rgba(254, 242, 242, 0.9);
  color: #dc2626;
  border: 1px solid #fecaca;
}

.responseMessage {
  background-color: rgba(240, 253, 244, 0.9);
  color: #16a34a;
  border: 1px solid #bbf7d0;
}

@media (prefers-color-scheme: dark) {
  .loginBox {
    background: rgba(31, 41, 55, 0.95);
  }

  .logoSection h1 {
    color: #f9fafb;
  }

  .subtitle {
    color: #9ca3af;
  }

  .formGroup label {
    color: #e5e7eb;
  }

  .input {
    background: rgba(55, 65, 81, 0.9);
    border-color: #4b5563;
    color: white;
  }

  .input::placeholder {
    color: #9ca3af;
  }
}

@media screen and (max-width: 768px) {
  .loginContainer {
    padding: 1rem;
    justify-content: center;
    min-height: calc(100vh - 120px);
  }

  .loginForm {
    width: 90%;
    padding: 1.5rem;
  }

  .formGroup {
    margin-bottom: 1rem;
  }

  .formGroup input {
    padding: 0.5rem;
  }

  .submitButton {
    padding: 0.5rem;
  }
}

/* For very small screens */
@media screen and (max-width: 380px) {
  .loginForm {
    width: 95%;
    padding: 1rem;
  }
}

.excelPreviewContainer {
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
}

.excelPreviewDownloadButton {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.excelPreviewContainer h2 {
  color: rgb(18, 8, 60);
  text-align: center;
  margin-bottom: 10px;
}

.smallDisclaimer {
  color: #6b7280;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

.excelPreviewContainer p:first-of-type {
  color: rgb(40, 60, 8);
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
